import React from 'react';
import ChevronLeft from '../assets/chevron-left.svg';

ScrollBar.defaultProps = {
    contentScroll: {
        currentScroll: 0,
        maxScroll: 100
    }

}
function ScrollBar(props) {
    const { contentScroll } = props;
    console.log(contentScroll);
    const scrollPercentage = ( contentScroll.currentScroll / contentScroll.maxScroll )*100;
    return (
        <div id="scrollbar">
            <div id="back-button">
                <a href="/#">
                    <ChevronLeft />
                </a>
            </div>
            <div className="scroll-container slideIn-left">
                <div style={{
                    height: `${scrollPercentage}%`
                }}
                className="inner-scroll">
                </div>
            </div>
        </div>
    )
}

export default ScrollBar;
