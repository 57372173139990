import React, { useState, useRef, useLayoutEffect } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ScrollBar from "../components/scrollBar"

const Project = ({ data }) => {
    const contentRef = useRef();
  const {
    markdownRemark: {
      html,
      tableOfContents,
      frontmatter: { short_description, title, link, date, banner },
    },
  } = data

    const updateContentScroll = (el) => {
        setContentScroll({
            currentScroll: ( el.scrollTop),
            maxScroll: (el.scrollHeight - el.clientHeight),
        })
    }

    useLayoutEffect(() => {
        if (contentRef.current) {
            updateContentScroll(contentRef.current);
        }
    }, []);

  const [contentScroll, setContentScroll] = useState(0)


  return (
    <Layout>
        <SEO title={title} />
      <div className="center-padding ">
        <div className="outer-border min-height-content-page">
          <div
            className="relative hide-scrollbar min-height-content-page l-content-page primary-border-ten"
          >
            <ScrollBar contentScroll={contentScroll} />
            <div
                ref={contentRef}
                onScroll={el => {
                    updateContentScroll(el.currentTarget);
                }}
                className="scroll">
                <div className="banner background-primary slideIn-left">
                    <img src={banner} />
                </div>
                <div className="intro slideIn-left">
                    <div className="full-width">
                        <h1> {title} </h1>
                    </div>
                    <div className="flex-row slideIn-left">
                        <div className="">
                            <h3> Year </h3>
                            <p>{date}</p>
                        </div>
                        <div className="index slideIn-left">
                            <h3> Index </h3>
                            <div
                                dangerouslySetInnerHTML={{ __html: tableOfContents }}
                                className="written-content block-nav nav"
                            ></div>
                        </div>
                        <div className="description flex-grow slideIn-left">
                            <h3> Short Description </h3>
                            {short_description}
                            {link ? (
                                <p>
                                    {" "}
                                    <a href={link}> View the project here </a>{" "}
                                </p>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div
                    dangerouslySetInnerHTML={{ __html: html }}
                    className="main-content"
                ></div>
            </div>
        </div>
    </div>
      </div>
  </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      tableOfContents
      frontmatter {
        title
        link
        date
        banner
        short_description
      }
    }
  }
`

export default Project
